import React from "react"
import { graphql } from "gatsby"
import { Box } from "@mui/material"

import Header from "../../components/Header"
import NavBar from "../../components/Navbar/Navbar"
import Title from "../../components/Title"
import Footer from "../../components/Footer"

const BlogDetails = props => {
  const data = props.data

  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap">
      <Header />
      <NavBar />
      <Title title="SOME WORD FOR BLOG" subTitle={data.wordpressPost.title} />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }} />
      </div>
      <Footer />
    </Box>
  )
}

export default BlogDetails

export const postQuery = graphql`
  query($slug: String) {
    wordpressPost(slug: { eq: $slug }) {
      id
      slug
      title
      content
    }
  }
`
